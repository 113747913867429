<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='1' >

    <div class="w">
      <!-- banner开始 -->
      <div class="w">
        <banner class="w-full" title="ABOUT" :bgImg="bgImg" ></banner>
      </div>
      <!-- banner结束 -->

      <!-- 关于双美 开始 -->
      <div class="about01 w row p-0 m-0 bg-white" >
        <steps class="container-left col-md-1 p-0 m-0" num='01' title='关于双美' color='#CA4938' ></steps>
        <div class="center col-md-10 p-0 m-0 ">
          <headlines class="headlines font-XQ" title='双美豆沙牛乳' english="ABOUT" color="#1E456C" ></headlines>
          <div class="about-content text-blue ml-100 wow fadeInUp">
            <p>好事成双、纯美相伴，以双美之念映四时之季，琢朴，纯然。</p>
            <p>春生夏长，秋收冬藏，取予有节，出入有时，开阖张歙，不失其叙，喜怒刚柔，不离其理。 </p>
            <p>美者，是念想、是期盼，</p>
            <p>时者，是量度、是见证，</p>
            <p>恰量最适合的维度成就美，赏见最踏实的手艺塑造美；</p>
            <p>双美熠熠，四时相候，</p>
            <p>以双美之物为媒，永结悠然岁月之景。</p>
          </div>
          <div class="about01-img"></div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <!-- 关于双美 结束 -->
      <!-- 品牌故事 开始 -->
      <div class="about02 w row p-0 m-0 bg-white" >
        <steps class="container-left col-md-1 p-0 m-0" num='02' title='品牌故事' color='#CA4938' ></steps>
        <div class="center col-md-11 p-0 m-0 ">
          <headlines class="headlines pc-show font-XQ" title='记忆里妈妈的味道' english="BRAND STORY" color="#1E456C" align='left' ></headlines>
          <headlines class="headlines mb-show font-XQ" title='记忆里妈妈的味道' english="BRAND STORY" color="#1E456C" align='center' ></headlines>

          <div class="about-content text-blue wow fadeInUp">
            <p>台湾的秋，不下雨的时候总觉得闷热，母亲常在这个时节为孩子们准备营养败火的豆沙甜食。</p>
            <p>这天一早阿柱母亲如往常一样在早市择好了新鲜的高山绿毛豆，硕大的绿豆在母亲的多道工序后，慢</p>
            <p>慢变成了绵软的豆沙。为了呈现最佳口感，妈妈将处理好的豆沙静置一旁，出门去了... ...</p>
            <p>妈妈刚走，阿柱家的小妹便溜了进来， 正当四处寻觅时， 一不小心竟把母亲准备的豆沙打翻在一旁的牛奶中。小妹害怕妈姆的责怪急急来寻哥哥阿柱，阿柱望著</p>
            <p>窗外穿过云朵的鸿雁，灵机一动，将混了豆沙的牛乳放入冰箱冷藏。</p>
            <p>外出归来的母亲，无意尝到这特殊的饮料，竟赞不绝口。</p>
          </div>
        </div>
      </div>
      <!-- 品牌故事 结束 -->

      <!-- 品牌理念 开始 -->
      <div class="about03 w row p-0 m-0" >
        <steps class="container-left col-md-1 p-0 m-0" num='03' title='品牌理念' color='#CA4938' ></steps>
        <div class="center col-md-10 p-0 m-0 ">
          <idea class="w-full"></idea>
        </div>
        <div class="pc-show col-md-1 p-0 m-0 "></div>
      </div>
      <!-- 品牌理念 结束 -->

      <!-- 发展历程 开始 -->
      <div class="about04 w row p-0 m-0" >
        <steps class="container-left col-md-1 p-0 m-0" num='04' title='发展历程' color='#fff' ></steps>
        <div class="center col-md-11 p-0 m-0 ">
          <headlines class="headlines font-XQ" title='双美一路走来' english="ABOUT US" color="#fff" align='left' ></headlines>
          <div class="text-whiteMilk mt-25 wow fadeInUp">自2020年成立，发展至今历时2年，共创建200+店 ，入驻53座城市，我们依然继续努力前行，秉持初心，保持自我</div>
        </div>
      </div>
      <div class="about-swiper w row p-0 m-0 bg-white">
        <div class="center h-full col-md-11 p-0 m-0 ">
          <swiperDevelopment v-if="developmentList.length" :list='developmentList' class="pc-show"></swiperDevelopment>
          <swiperDevelopmentMb :list='developmentList' class="mb-show"></swiperDevelopmentMb>
        </div>
      </div>
      <!-- 发展历程 结束 -->

    </div>
  </Containers>
</template>

<script>
import { WOW } from 'wowjs'
import banner from '@/components/banner'
import headlines from '@/components/headlines'

import steps from '@/components/steps'
import idea from '@/components/idea'
import swiperDevelopment from '@/components/swiperDevelopment'
import swiperDevelopmentMb from '@/components/swiperDevelopmentMb'

export default {
  metaInfo: {
    title: '关于我们_双美豆沙牛乳官方网站',
    meta: [
      {
        name: 'keywords',
        content: '关于双美豆沙牛乳_双美豆沙牛乳品牌介绍'
      },
      {
        name: 'description',
        content: '本页面为您提供双美豆沙牛乳品牌基本介绍，包含双美豆沙牛乳品牌起源、双美豆沙牛乳品牌发展历程等相关信息，帮助您更加详细、全面的了解我们的品牌。'
      }
    ]
  },
  data () {
    return {
      status: 'success',
      developmentList: [],
      bgImg: 'https://s3.moke.cloud/shuangmei/5b473a67-d08d-42ac-a5b8-c5e5bb8594f3.jpg',
    }
  },
  mounted () {
    this.getInit()
  },
  components: {
    banner,
    headlines,
    steps,
    idea,
    swiperDevelopment,
    swiperDevelopmentMb
  },
  computed: {
  },
  methods: {
    funEnd () {
      // setTimeout(() => {
      //   this.status = 'success'
      //   this.$nextTick(() => {
      //     // 在dom渲染完后,再执行动画
      //     const wow = new WOW({
      //       live: false
      //     })
      //     wow.init()
      //   })
      // }, 300)
    },
    async getInit () {
      try {
        await this.getDevelopment()
        // this.status = 'requestEnd'
        setTimeout(() => {
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false
            })
            wow.init()
          })
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
    },
    async getDevelopment () {
      let list = await this.$axios.get('/classes/development', {
        params: {
          order: '-weight',
        }
      })
      this.developmentList = list.results
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

.about01{
  height: 995px;
  @include hamburger {
    height: 460px;
  }
  .center{
    padding-top: 110px !important;
    @include hamburger {
      padding-top: 0px !important;
    }
  }
  .about01-img{
    width: 100%;
    height: 683px;
    margin-top: 80px;
    background: url(../assets/images/shop-bg.jpg) no-repeat center;
    background-size: cover;
    box-sizing: border-box;
    @include hamburger {
      height: 154px;
      width: 96%;
      margin: 0 auto;
    }
  }
  .about-content{
    margin-top: 60px;
    @include hamburger {
      margin: 20px;
    }
  }
}
.about02{
  height: 656px;
  margin-top: 494px !important;
  @include hamburger {
    height: auto;
    margin-top: 100px !important;
  }
  .center{
    padding-top: 100px !important;
    padding-left: 80px !important;
    @include hamburger {
      padding: 0px !important;
    }
  }
  .about-content{
    margin-top: 40px;
    @include hamburger {
      margin: 20px;
    }
  }
}
.about03{
  background-color: #FCFAF0;
  @include hamburger {
    padding: 0 12px 12px !important;
  }
  .center{
    padding: 120px 0 !important;
    @include hamburger {
      padding: 0 !important;
    }
  }
  .about-content{
    margin-top: 40px;
    @include hamburger {
      margin: 20px;
    }
  }
}

.about04{
  height: 515px;
  @include hamburger {
    height: auto;
  }
  .center{
    padding: 120px 0 !important;
    @include hamburger {
      padding: 0px 12px 100px!important;
    }
    .text-whiteMilk{
      @include hamburger {
        margin-top: 10px;
      }
    }
  }
  .about-content{
    margin-top: 40px;
  }
}

.about-swiper{
  height: 716px;
  position: relative;
  @include hamburger {
    height: 391px;
  }
  .center{
    position: absolute;
    top: -127px;
    // left: 140px;
    right: 0;
    @include hamburger {
      height: 480px;
      top: unset;
      bottom: 0;
      padding: 0 12px !important;
    }
  }
}

.container-left{
  @include hamburger {
    height: auto !important;
  }
}
.about-content{
  p{
    margin-bottom: 15px;
    @include hamburger {
      margin-bottom: 0px;
    }
  }
}
</style>
